import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import Modal from "../../components/common/modal/modal";
import UploadImage from "../../components/common/upload-image/upload-image";
import { MdDeleteOutline } from "react-icons/md";
import { useEffect } from "react";
import Editor from "./editor";

function CreateNews() {
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  const fields = useFormik({
    initialValues: {
      title: "",
      HTML_text: "",
      type: "banner",
      status: "inactive",
      images: [],
      image: "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    fields.setFieldValue("images", images);
  }, [images]);

  useEffect(() => {
    setImages(fields.values.images);
  }, []);

  // error message
  const notify = (text) => toast.error(text);

  const createNews = () => {
    axios
      .post(
        `https://tiin-cashback.in1.uz/api/banner`,
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        alert("Title length and description must be longer");
        if (error.response.status === 403) {
          notify("You have no permission");
        }
      });
  };

  return (
    <div>
      <button onClick={() => setShow(true)}>Create</button>
      {show && (
        <Modal
          open={show}
          setOpen={setShow}
          width={window.innerWidth < 900 ? "350px" : "650px"}
        >
          <h2>Edit</h2>
          <label htmlFor="title">
            <p>Title</p>
            <input
              type="text"
              placeholder="Title"
              {...fields.getFieldProps("title")}
            />
          </label>
          {fields.values.type === "news" && (
            <Editor
              content={fields.values.HTML_text}
              onChange={fields.setFieldValue}
            />
          )}

          <div className="grid2">
            <label>
              <p>Status</p>
              <CustomDropdown
                defaultOption={{
                  value: fields.values.status,
                  label:
                    fields.values.status === "active" ? "Active" : "Inactive",
                }}
                options={[
                  {
                    value: "active",
                    label: "Active",
                  },
                  {
                    value: "inactive",
                    label: "Inactive",
                  },
                ]}
                onChange={(e) => fields.setFieldValue("status", e.value)}
              />
            </label>
            <label>
              <p>Type</p>
              <CustomDropdown
                defaultOption={{
                  value: fields.values.type,
                  label: fields.values.type === "news" ? "News" : "Post",
                }}
                options={[
                  {
                    value: "banner",
                    label: "Banner",
                  },
                  {
                    value: "news",
                    label: "News",
                  },
                ]}
                onChange={(e) => fields.setFieldValue("type", e.value)}
              />
            </label>
            {fields.values.type === "banner" && (
              <UploadImage
                onChange={fields.setFieldValue}
                width={250}
                heigth={150}
                url={fields.values.image}
              />
            )}
            {fields.values.type === "news" && (
              <>
                <UploadImage
                  onChange={setImages}
                  images={images}
                  width={250}
                  heigth={150}
                  url={fields.values.image}
                />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: "0px 18px",
                  }}
                >
                  {images.map((image, index) => (
                    <div key={index} className="image-element">
                      <span className="image-element-delete">
                        <MdDeleteOutline
                          onClick={() => {
                            setImages(images.filter((_, i) => i !== index));
                          }}
                        />
                      </span>
                      <img key={index} width="100%" src={image} alt="" />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              onClick={() => setShow(false)}
              type="button"
              style={{ color: "#EB5757", background: "#FFEFEF" }}
            >
              Cancel
            </button>
            <button onClick={createNews}>Save</button>
          </div>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
}

export default CreateNews;
