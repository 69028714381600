import styled from "styled-components";
import { Modal } from "react-tiny-modal";

export const AsModal = styled(Modal)`
  z-index: 9999 !important;
  background: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
`;

export const Card = styled.div`
  width: 1000px;
  padding: 35px 30px;
  border-radius: 8px;
  background-color: #fff;
  h2 {
    margin: 24px 0;
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 20px;
  }
  label {
    display: block;
    margin-bottom: 20px;
  }
  input {
    width: 100%;
  }
  .modal-footer {
    display: flex;
    gap: 30px;
    justify-content: center;
  }
  @media (max-width: 1000px) {
    display: grid;
    align-items: center;
    width: 350px !important;
    margin: 0 auto;
    padding: 30px 35px;
    overflow: auto;
    .modal-footer {
      gap: 15px;
    }
    label {
    }
  }
`;
