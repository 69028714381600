import { useState } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/common/modal/modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomDropdown from "../../components/common/dropdown/dropdown";

function Client({ client }) {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const fields = useFormik({
    initialValues: {
      _id: client._id,
      first_name: client.first_name,
      last_name: client.last_name,
      phone_number: client.phone_number,
      status: client.status,
      point_balance: client.point_balance,
    },
  });

  // error message
  const notify = (text) => toast.error(text);

  const updateClient = () => {
    axios
      .patch(
        `https://tiin-cashback.in1.uz/api/clients/${client._id}`,
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        if (error.response.statusText === "Forbidden") {
          notify("You have no permission");
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <tr onClick={() => setModal(true)}>
        <td>{fields.values.first_name}</td>
        <td>
          {fields.values.last_name ? (
            fields.values.last_name
          ) : (
            <span className="gray">Not filled</span>
          )}
        </td>
        <td>{fields.values.phone_number}</td>
        <td>
          {Math.round(fields.values.point_balance).toLocaleString()}{" "}
          <span className="currency">UZS</span>
        </td>
        <td>
          {Math.round(client.total_sale).toLocaleString()}{" "}
          <span className="currency">UZS</span>
        </td>
        <td>{client.sales}</td>
      </tr>
      {modal && (
        <Modal open={modal} setOpen={setModal} width="550px">
          <h2>Edit client</h2>
          <label htmlFor="first_name">
            <p>First name</p>
            <input
              type="text"
              placeholder="First name"
              {...fields.getFieldProps("first_name")}
            />
          </label>
          <label htmlFor="last_name">
            <p>Last name</p>
            <input
              type="text"
              placeholder="Last name"
              {...fields.getFieldProps("last_name")}
            />
          </label>
          <label htmlFor="phone_number">
            <p>Phone number</p>
            <input
              type="tel"
              placeholder="Phone_number"
              {...fields.getFieldProps("phone_number")}
            />
          </label>
          <label htmlFor="point_balance">
            <p>Balance</p>
            <input
              type="number"
              placeholder="Balance"
              {...fields.getFieldProps("point_balance")}
            />
          </label>
          {/* <label htmlFor="status">
            <p>Status</p>
            <input
              type="number"
              placeholder="Balance"
              {...fields.getFieldProps("point_balance")}
            />
          </label> */}
          <div className="modal-footer">
            <button
              onClick={() => setModal(false)}
              type="button"
              style={{ color: "#EB5757", background: "#FFEFEF" }}
            >
              Cancel
            </button>
            <button onClick={updateClient}>Save</button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Client;
