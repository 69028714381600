import styled from "styled-components";

const FlexCommon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(FlexCommon)`
  justify-content: center;
  padding: 18px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const FlexContainer = styled(FlexCommon)`
  width: 320px;
  justify-content: space-between;
  color: #878a99;
  button i {
    margin: 0 !important;
  }
  input {
    width: 60px;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.9rem;
    border-radius: 4px;
    color: var(--text-body);
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  @media (max-width: 800px) {
    * {
      font-size: 14px;
    }
    input {
      width: 50px;
    }
  }
`;
