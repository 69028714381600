import { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import Header from "../../components/common/header/header";
import Navigation from "../../components/common/navigation/navigation";
import Search from "../../components/common/search/search";
import Loader from "../../components/common/loader/loader";
import { Wrapper } from "./clients.e";
import { FlexRow } from "../../components/common/global/global";
import useAxiosFetch from "../../hooks/useAxios";
import Client from "./client";
import axios from "axios";
dayjs.extend(localizedFormat);

function Clients() {
  const [clientSearch, setClientSearch] = useState("");
  const [clientPage, setClientPage] = useState(1);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { data: clients, loaded } = useAxiosFetch(
    `https://tiin-cashback.in1.uz/api/clients?search=${clientSearch}&page=${clientPage}`,
    null
  );

  const getAll = () => {
    setDownloadLoading(true);
    axios
      .get("https://tiin-cashback.in1.uz/api/clients?page=1&limit=9999999", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).access_token
          }`,
        },
      })
      .then((res) => {
        let custom = [];

        res.data.data.map((item, i) => {
          custom[i] = {
            first_name: item.first_name,
            last_name: item.last_name,
            phone_number: item.phone_number,
            point_balance: Math.round(item.point_balance).toLocaleString(),
            total_sale: Math.round(item.total_sale).toLocaleString(),
            sales: item.sales,
            gender: item.gender,
            user_id: item.user_id,
          };
        });
        exportToCSV(custom, "tiin-clients");
        setDownloadLoading(false);
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Wrapper>
      <Header title="Clients" />
      {/* {!loaded && <Loader />} */}
      <div>
        <div>
          <FlexRow>
            <Search value={clientSearch} onChange={setClientSearch} />
            {loaded && (
              <p>
                Total cashback:{" "}
                <strong>
                  {clients &&
                    Math.round(clients?.total_cashback).toLocaleString()}{" "}
                  sum
                </strong>
              </p>
            )}
            <button type="button" onClick={getAll} disabled={downloadLoading}>
              {downloadLoading ? "Loading ..." : "Download"}
            </button>
          </FlexRow>
          <div style={{ minHeight: "54.6vh", overflowX: "auto" }}>
            {!loaded && <Loader />}
            {loaded && (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Surname</th>
                    <th>Phone number</th>
                    <th>Point Balance</th>
                    <th>Total spend</th>
                    <th>Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {clients?.data?.map((client, i) => {
                    return <Client key={i} client={client} />;
                  })}
                </tbody>
              </table>
            )}
          </div>
          {clients && (
            <Navigation
              current={clientPage}
              total={Math.ceil(clients.total / 10)}
              next={() => setClientPage(clientPage + 1)}
              prev={() => setClientPage(clientPage - 1)}
              onChange={setClientPage}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default Clients;
