import { Wrapper } from "./search.e";
import { IoSearch } from "react-icons/io5";

function Search({ small, onChange, value }) {
  const handleSearch = (e) => onChange(e.target.value);
  return (
    <Wrapper className={small ? "small" : "search"}>
      <IoSearch fontSize={26} />
      <input
        onChange={handleSearch}
        value={value}
        placeholder="Search anything"
      />
    </Wrapper>
  );
}

export default Search;
