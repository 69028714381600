import { Wrapper } from "./upload-image.e";
import axios from "axios";
import { MdDeleteOutline } from "react-icons/md";

function UploadImage({ onChange, url, width, heigth, images }) {
  return (
    <Wrapper
      style={{
        width: width,
        height: heigth,
      }}
    >
      <img width="100%" style={{ objectFit: "cover" }} src={url} alt="" />

      <label width={width} height={heigth}>
        <input
          type="file"
          accept="image/png, image/jpeg, image/webp"
          onChange={(e) => {
            if (e.target.files[0].size > 1048576) {
              alert("File size is too big. Max size is 1MB");
            } else {
              const bodyFormData = new FormData();
              bodyFormData.append("file", e.target.files[0]);
              axios
                .post(
                  "https://tiin-cashback.in1.uz/api/banner/image",
                  bodyFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${
                        JSON.parse(localStorage.getItem("user")).access_token
                      }`,
                    },
                  }
                )
                .then((res) => {
                  if (images) {
                    onChange([...images, res.data.path]);
                  } else {
                    onChange("image", res.data.path);
                  }
                });
            }
          }}
        />

        <figure>
          <img
            width="100%"
            style={{ objectFit: "cover" }}
            src={
              url.indexOf("http") !== -1
                ? url
                : "https://cdn.dribbble.com/users/4841378/screenshots/13953372/media/15649e4994d5627f09da52ce79913ae8.png?compress=1&resize=640x480&vertical=top"
            }
            className="personal-avatar"
            alt="avatar"
          />
          <figcaption>
            <i className="fa-light fa-camera" />
          </figcaption>
        </figure>
      </label>
    </Wrapper>
  );
}

export default UploadImage;
