import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Modal from "../../components/common/modal/modal";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import UploadImage from "../../components/common/upload-image/upload-image";
import { useEffect } from "react";
import Editor from "./editor";
dayjs.extend(localizedFormat);

function NewsEdit({ news }) {
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);
  const fields = useFormik({
    initialValues: {
      _id: news._id,
      title: news.title,
      HTML_text: news.HTML_text,
      type: news.type,
      status: news.status,
      image: news.image,
      images: news.images,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    setImages(fields.values.images);
  }, []);

  useEffect(() => {
    fields.setFieldValue("images", images);
  }, [images]);

  // error message
  const notify = (text) => toast.error(text);

  const updateNews = () => {
    axios
      .patch(
        `https://tiin-cashback.in1.uz/api/banner/${news._id}`,
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          notify("You have no permission");
          console.log(error.response);
        }
      });
  };

  const deleteNews = () => {
    axios
      .delete(`https://tiin-cashback.in1.uz/api/banner/${news._id}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).access_token
          }`,
        },
        data: {
          ...fields.values,
        },
      })
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          notify("You have no permission");
        }
      });
  };

  return (
    <>
      <tr onClick={() => setModal(true)}>
        <td>{fields.values.title}</td>
        <td dangerouslySetInnerHTML={{ __html: fields.values.HTML_text }} />
        <td>{fields.values.status}</td>
        <td>{dayjs(news.createdAt).format("DD.MM.YYYY - HH:MM")}</td>
        <td>{fields.values.type}</td>
        <td
          onClick={(e) => {
            e.stopPropagation();
            deleteNews();
          }}
        >
          <MdDeleteOutline fontSize={24} />
        </td>
      </tr>
      {modal && (
        <Modal
          open={modal}
          setOpen={setModal}
          width={window.innerWidth < 900 ? "350px" : "650px"}
        >
          <h2>Edit</h2>
          <label htmlFor="title">
            <p>Title</p>
            <input
              type="text"
              placeholder="Title"
              {...fields.getFieldProps("title")}
            />
          </label>

          <Editor
            content={fields.values.HTML_text}
            onChange={fields.setFieldValue}
          />

          <div className="grid2">
            <label>
              <p>Status</p>
              <CustomDropdown
                defaultOption={{
                  value: fields.values.status,
                  label:
                    fields.values.status === "active" ? "Active" : "Inactive",
                }}
                options={[
                  {
                    value: "active",
                    label: "Active",
                  },
                  {
                    value: "inactive",
                    label: "Inactive",
                  },
                ]}
                onChange={(e) => fields.setFieldValue("status", e.value)}
              />
            </label>
            <label>
              <p>Type</p>
              <CustomDropdown
                defaultOption={{
                  value: fields.values.type,
                  label: fields.values.type === "news" ? "News" : "Banner",
                }}
                options={[
                  {
                    value: "banner",
                    label: "Banner",
                  },
                  {
                    value: "news",
                    label: "News",
                  },
                ]}
                onChange={(e) => fields.setFieldValue("type", e.value)}
              />
            </label>
            <UploadImage
              onChange={setImages}
              width={250}
              images={images}
              heigth={150}
              url={fields.values.image}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: "0px 18px",
              }}
            >
              {images?.map((image, index) => (
                <div key={index} className="image-element">
                  <span className="image-element-delete">
                    <MdDeleteOutline
                      onClick={() => {
                        setImages(images.filter((_, i) => i !== index));
                      }}
                    />
                  </span>
                  <img key={index} width="100%" src={image} alt="" />
                </div>
              ))}
            </div>
          </div>

          <div className="modal-footer">
            <button
              onClick={() => setModal(false)}
              type="button"
              style={{ color: "#EB5757", background: "#FFEFEF" }}
            >
              Cancel
            </button>
            <button onClick={updateNews}>Save</button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default NewsEdit;
