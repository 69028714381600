import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --text: #1B212D;
        --text2: #929EAE;
        --gray: #78778B;
        --border-color: #F2F2F2;
        --main: #C8EE44;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Kumbh Sans', sans-serif;
        color: var(--text);
    }
    a {
        text-decoration: none;
    }
    h3 {
        font-weight: 500;
        font-size: 32px;
    }
    button, input {
        border: none;
        outline: none;
        background: none;
    }
    label {
      p {
        font-weight: 500 !important;
        font-size: 15px !important;
        margin-bottom: 10px;
        color: var(--text) !important;
      }
    }
    input, textarea {
        padding: 15px 20px;
        font-weight: 400;
        font-size: 14px;
        color: var(--gray);
        border: 1px solid var(--border-color);
        border-radius: 10px;
        color: var(--text);
        resize: none;
        width: 100%;
    }
    textarea {
        height: 120px;
        margin-bottom: 20px;
        outline: none;
    }
    button {
        padding: 13px 18px;
        background: var(--main);
        border: 1px solid #fff;
        border-radius: 12px;
        font-size: 16px;
        cursor: pointer;
        color: var(--text);
        font-weight: 600;
        letter-spacing: 0.5px;
        transition: 0.2s all ease-in-out;
        display: flex;
        align-items: center;
        gap: 12px;
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
    }
    .form-control {
      display: block;
      margin-bottom: 20px;
    }
    .box {
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      padding: 32px;
    }
    .relative {
      position: relative;
    }
    .grid2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
    .gray {
      color: var(--text2);
      font-size: 12px;
    }
    .select__control {
      padding: 7px 10px;
      font-weight: 400;
      font-size: 14px !important;
      border: 1px solid var(--border-color) !important;
      border-radius: 10px !important;
      color: var(--text) !important;
    }
    .rdrDateRangePickerWrapper {
      position: absolute;
      z-index: 11;
      top: 110px;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
      border-radius: 10px;
      overflow: hidden;
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .rdrInRange, .rdrEndEdge, .rdrStartEdge {
        background-color: var(--main);
      }
      * {
        color: #1B212D !important;
      }
    }
    .select__menu {
      z-index: 11 !important;
      border-radius: 8px !important;
      overflow: hidden;
    }
    .select__option {
      background-color: #fff !important;
      padding: 12px !important;
    }
    .select__option--is-selected  {
      background-color: var(--main) !important;
      color: var(--text) !important;
    }
    .select__menu-list {
      padding: 0 !important;
    }
    .apexcharts-legend {
      padding-top: 20px !important;
      gap: 15px;
    }
    .Toastify__toast {
      z-index: 1000;
      box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
    }
    .tox-statusbar {
      display: none !important;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }
    thead {
      text-align: left;
      position: sticky;
      top: 0;
      z-index: 10;
      background: #f7f8fa;
    }
    th {
      font-size: 16px !important;
    }
    tr {
      transition: 0.1s all ease-in-out;
      pointer-events: all;
    }
    tbody {
      overflow: hidden;
    }
    th:first-child,
    td:first-child {
      padding-left: 20px;
    }
    td,
    th {
      padding: 16px 0;
      font-weight: 400;
      min-width: 150px;
      padding-right: 30px !important;
    }
th {
  padding-top: 10px;
  font-weight: 500;
  font-size: 13px;
  color: #454545;
  padding: 15px 0;
  font-weight: 600;
}
tr:last-child td {
  border-bottom: none;
}
td {
  font-size: 14px;
  line-height: 16px;
  padding: 16px 0;
  border-bottom: 0.5px solid #f1f4f9;
  color: var(--text);
  line-height: 1.45;
}
  @media (min-width: 1200px) {
    table {
      min-width: 100% !important;
    }
  }
  @media (max-width: 900px) {
    .grid2 {
      grid-template-columns: 1fr !important;
    }
  }
  .image-element {
    position: relative;
  }
  .image-element-delete {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    padding: 2px 3px;
    border-radius: 0 0 0 10px;
    cursor: pointer;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const PageWrapper = styled.div`
  padding: 30px 40px;
  width: 100%;
  @media (max-width: 900px) {
    padding: 20px;
  }
`;
