import styled from "styled-components";

export const Wrapper = styled.div`
  h2 {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 30px;
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin: 30px 0;
  margin-top: 0;
  align-items: center;
  .btn {
    height: 90px;
    width: 100%;
    font-size: 18px;
    justify-content: center;
  }
  @media (max-width: 1700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1490px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Card = styled.div`
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 42px auto;
  align-items: center;
  grid-gap: 24px;
  background: #f8f8f8;
  border-radius: 10px;
  &:first-child {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  }
  cursor: pointer;
  span {
    color: var(--text2);
    font-weight: 400;
    font-size: 14px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    margin-top: 2px;
    span {
      font-size: 12px;
      margin-left: 2px;
      font-weight: 500;
    }
  }
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ebe8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(2px);
    svg {
      color: #363a3f;
    }
  }
  &.active {
    background-color: #363a3f;
    p {
      color: #fff;
    }
    .icon {
      background-color: #4e5257;
      svg {
        fill: var(--main);
      }
    }
  }
  @media (max-width: 1000px) {
    box-shadow: none !important;
  }
`;
export const ChartWrapper = styled.div`
  margin: 50px 0;
  transform: translateX(-15px);
`;

export const ReportsGrid = styled.div`
  display: grid;
  grid-template-columns: 65% 32%;
  grid-gap: 40px;
  .mt {
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    overflow-x: scroll;
  }
  .mt-2 {
    margin-top: 0px;
    margin-bottom: 15px !important;
  }
  td,
  th {
    padding: 16px 0;
    font-size: 13px;
    &:first-child {
      padding-left: 20px;
    }
  }
  h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
  }
  @media (max-width: 1490px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    .mt-2 {
      width: 100%;
    }
  }
`;
