import { useContext, useState } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Button from "../../components/common/button/button";
import { AuthContext } from "../../context/auth";
import { Wrapper, LoginWrapper } from "./auth.e";
import LoginImg from "../../assets/images/login-img.png";

function Auth() {
  const [loading, setLoading] = useState(false);
  const { setIsLogged } = useContext(AuthContext);

  // login form fields
  const fields = useFormik({
    initialValues: {
      user_name: "",
      password: "",
    },
  });

  // error message for login form
  const notify = () => toast.error("Incorrect");

  // login form submit
  const login = () => {
    setLoading(true);
    axios
      .post("https://tiin-cashback.in1.uz/api/auth/moderator/login", {
        ...fields.values,
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        setLoading(false);
        setIsLogged(true);
        window.location.reload(false);
      })
      .catch(() => {
        notify();
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <LoginWrapper>
        <div>
          <h3>Welcome back</h3>
          <p>Welcome back! Please enter your details</p>
          <form onSubmit={login}>
            <label htmlFor="user_name" className="form-control">
              <p>Username</p>
              <input
                type="text"
                placeholder="Enter your username"
                autoComplete="off"
                {...fields.getFieldProps("user_name")}
              />
            </label>
            <label htmlFor="password">
              <p>Password</p>
              <input
                type="password"
                autoComplete="off"
                placeholder="Enter your password"
                {...fields.getFieldProps("password")}
              />
            </label>
            <Button
              loading={loading}
              onClick={login}
              text="Sign in"
              color="primary"
              full
              type="submit"
            />
          </form>
        </div>
      </LoginWrapper>
      <img src={LoginImg} alt="Tiin cashback" />
      <ToastContainer />
    </Wrapper>
  );
}

export default Auth;
