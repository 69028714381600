import styled from "styled-components";

export const Wrapper = styled.div`
  tr {
    cursor: pointer;
  }
  .currency {
    font-size: 11px;
    color: var(--text2);
    padding-left: 2px;
  }
`;
