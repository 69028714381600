import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    div::last-child() {
      display: none;
    }
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 24%;
  div {
    width: 100%;
  }
  button {
    justify-content: center;
  }
  p {
    color: var(--gray);
    margin-top: 3px;
    font-weight: 400;
    font-size: 16px;
  }
  form {
    margin: 32px 0;
    input {
      width: 100%;
    }
    button {
      margin-top: 30px;
    }
  }
  @media (max-width: 1400px) {
    padding: 0 12%;
  }
`;
