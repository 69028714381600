import { createContext, useState } from "react";

export const HeaderContext = createContext();

export function HeaderProvider(props) {
  const [show, setShow] = useState(false);

  return (
    <div>
      <HeaderContext.Provider value={{ show, setShow }}>
        {props.children}
      </HeaderContext.Provider>
    </div>
  );
}
