import { useEffect } from "react";
import { AsModal, Card } from "./modal.e";

function Modal({ width, open, setOpen, children }) {
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <AsModal onClose={handleClose} isOpen={open}>
      <Card style={{ width: width }}>{children}</Card>
    </AsModal>
  );
}

export default Modal;
