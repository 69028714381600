import { useContext } from "react";
import { Links, Wrapper } from "./menu.e";
import { NavLink } from "react-router-dom";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { FaPollH } from "react-icons/fa";
import Logo from "../../assets/images/logo.svg";
import { MdOutlineClose } from "react-icons/md";
import { HeaderContext } from "../../context/header";

function Menu() {
  const { show, setShow } = useContext(HeaderContext);
  return (
    <Wrapper style={show ? { left: "0" } : { left: "-100%" }}>
      <MdOutlineClose
        className="closer"
        fontSize={30}
        onClick={() => setShow(false)}
      />
      <img src={Logo} alt="Tiinco admin" />
      <Links>
        <NavLink
          to="/reports"
          onClick={() => setShow(false)}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <RiDashboardFill fontSize={24} />
          Reports
        </NavLink>
        <NavLink to="/clients" onClick={() => setShow(false)}>
          <FaUserFriends fontSize={25} />
          Clients
        </NavLink>
        <NavLink to="/news" onClick={() => setShow(false)}>
          <FaPollH fontSize={24} />
          News
        </NavLink>
        {/* <NavLink to="/markets">
          <RiSettings4Fill fontSize={24} />
          Settings
        </NavLink> */}
      </Links>
      <NavLink
        onClick={() => {
          localStorage.removeItem("user");
          window.location.href = "/";
          setShow(false);
        }}
        to="/logout"
        style={{ marginTop: "auto" }}
      >
        <RiLogoutCircleRFill fontSize={22} />
        Log out
      </NavLink>
    </Wrapper>
  );
}

export default Menu;
