import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./pages/auth/auth";
import Reports from "./pages/reports/reports";
import Clients from "./pages/clients/clients";
import News from "./pages/news/news";
import Menu from "./components/menu/menu";
import { AuthContext } from "./context/auth";
import {
  Flex,
  GlobalStyle,
  PageWrapper,
} from "./components/common/global/global";

function App() {
  const { isLogged } = useContext(AuthContext);

  return (
    <>
      <BrowserRouter>
        <GlobalStyle />
        {isLogged ? (
          <>
            <Flex>
              <Menu />
              <PageWrapper>
                <Routes>
                  <Route path="*" element={<Reports />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/news" element={<News />} />
                </Routes>
              </PageWrapper>
            </Flex>
          </>
        ) : (
          <Auth />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
