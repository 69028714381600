import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import { IoWallet } from "react-icons/io5";
import { TbCalendar } from "react-icons/tb";
import Header from "../../components/common/header/header";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import Loader from "../../components/common/loader/loader";
import { FlexRow } from "../../components/common/global/global";
import { Card, Cards, Wrapper, ChartWrapper, ReportsGrid } from "./reports.e";
import useAxiosFetch from "../../hooks/useAxios";
dayjs.extend(localizedFormat);

function Reports() {
  const [showDate, setShowDate] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 10),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);
  const { data: sales, loaded } = useAxiosFetch(
    `https://tiin-cashback.in1.uz/api/reports/sale/${
      new Date(state[0].startDate).getTime() + 86400000
    }/${new Date(state[0].endDate).getTime() + 86400000}`,
    {
      count: 1,
      count_type: 2,
      custom: false,
      employees: [],
      end: 12,
      from: 23,
      organizations: [],
      services: [],
      start: 1,
      target: "gross_profit",
      to: 32,
    }
  );
  const [topType, setTopType] = useState({
    label: "All count",
    value: "count",
  });
  const { data: topSearch } = useAxiosFetch(
    `https://tiin-cashback.in1.uz/api/reports/top-search/${new Date(
      state[0].startDate
    ).getTime()}/${new Date(state[0].endDate).getTime()}`,
    null
  );
  const [chartData, setChartData] = useState();
  const [salesData, setSalesData] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (sales) {
      const dates = sales.data.map((item) => dayjs(item[0]).format("DD MMM"));
      const salesData = sales.data.map((item) => Math.round(item[1]));
      const usedCashback = sales.used_cashbacks.map((item) =>
        Math.round(item[1])
      );
      setSalesData(salesData);
      setDates(dates);
      setChartData([salesData, usedCashback, dates]);
    }
  }, [sales]);

  // all settings for chart 📊
  const chart_settings = {
    series: [
      {
        name: "Cashbacks",
        data: salesData,
      },
      {
        name: "Used cashbacks",
        data: chartData ? chartData[1] : [],
      },
    ],
    options: {
      // Chart
      chart: {
        height: 350,
        type: "area",
        toolbar: { show: false },
        zoom: { enabled: false },
        foreColor: "#727272",
        fontFamily: "Kumbh Sans",
      },
      colors: ["#29A073", "#C8EE44", "#0133C6"],

      // Fill
      fill: {
        opacity: 1,
        gradient: {
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },

      // Stroke
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "round",
      },

      // Grid
      grid: {
        strokeDashArray: 3,
        borderColor: "#e5e9f2",
      },

      // Datalabels
      dataLabels: { enabled: false },

      // Xaxis
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
        categories: dates,
      },

      // Yaxis
      yaxis: {
        labels: {
          formatter: function (value) {
            return value?.toLocaleString();
          },
        },
      },

      // Markers
      markers: {
        size: 0,
        strokeColors: "#5243AA",
        strokeWidth: 6,
      },

      // Tooltip
      tooltip: {
        x: {
          show: false,
        },
        labels: {
          formatter: function (value) {
            return value?.toLocaleString();
          },
        },
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: 13,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: "red",
      },
    },
  };

  return (
    <Wrapper>
      <Header title="Reports" />
      {!loaded && <Loader />}
      {loaded && sales && (
        <ReportsGrid>
          <div className="mt" style={{ overflowX: "inherit" }}>
            <Cards>
              <Card className="active">
                <div className="icon">
                  <IoWallet fontSize={23} />
                </div>
                <div>
                  <span>Cashbacks</span>
                  <p>
                    {Math.round(sales.cash_back).toLocaleString()}{" "}
                    <span>UZS</span>
                  </p>
                </div>
              </Card>
              <Card>
                <div className="icon">
                  <IoWallet fontSize={23} />
                </div>
                <div>
                  <span>Used cashback</span>
                  <p>
                    {Math.round(sales.used_cashback).toLocaleString()}.00{" "}
                    <span>UZS</span>
                  </p>
                </div>
              </Card>
              <div className="relative">
                <button className="btn" onClick={() => setShowDate(!showDate)}>
                  <TbCalendar fontSize={24} />
                  {`${dayjs(state[0].startDate).format("MMM DD")} - 
            ${dayjs(state[0].endDate).format("MMM DD")}`}
                </button>
                {showDate && (
                  <DateRangePicker
                    className="date mobile"
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    maxDate={new Date()}
                  />
                )}
              </div>
            </Cards>
            <ChartWrapper>
              <ReactApexChart
                options={chart_settings.options}
                series={chart_settings.series}
                type="area"
                height={450}
              />
            </ChartWrapper>
          </div>
          <div className="mt">
            <FlexRow className="mt-2" style={{ alignItems: "flex-start" }}>
              <h2>Top Searched</h2>
              <CustomDropdown
                onChange={setTopType}
                defaultOption={topType}
                options={[
                  {
                    label: " All Count",
                    value: "count",
                  },
                  {
                    label: "Found count",
                    value: "found_count",
                  },
                  {
                    label: "Not found count",
                    value: "not_found_count",
                  },
                  ,
                ]}
              />
            </FlexRow>
            <table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Barcode</th>
                  {topType.value === "count" && <th>Count</th>}
                  {topType.value === "found_count" && <th>Found count</th>}
                  {topType.value === "not_found_count" && (
                    <th>Not found count</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {topSearch?.data?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i}.</td>
                      <td>{item.barcode}</td>
                      {topType.value === "count" && <td>{item.count}</td>}
                      {topType.value === "found_count" && (
                        <td>{item.found_count}</td>
                      )}
                      {topType.value === "not_found_count" && (
                        <td>{item.notfound_count}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div>
            <FlexRow>
              <h2>Top clients</h2>
              <Search value={clientSearch} onChange={setClientSearch} />
            </FlexRow>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Phone number</th>
                  <th>Point Balance</th>
                  <th>Total spend</th>
                  <th>Sales</th>
                </tr>
              </thead>
              <tbody>
                {clients?.data?.map((client, i) => {
                  return (
                    <tr key={i}>
                      <td>{client.first_name}</td>
                      <td>
                        {client.last_name ? (
                          client.last_name
                        ) : (
                          <span className="gray">Not filled</span>
                        )}
                      </td>
                      <td>{client.phone_number}</td>
                      <td>
                        {Math.round(client.point_balance).toLocaleString()}
                      </td>
                      <td>{Math.round(client.total_sale).toLocaleString()}</td>
                      <td>{client.sales}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {clients && (
              <Navigation
                current={clientPage}
                total={Math.ceil(clients.total / 10)}
                next={() => setClientPage(clientPage + 1)}
                prev={() => setClientPage(clientPage - 1)}
                onChange={setClientPage}
              />
            )}
          </div> */}
        </ReportsGrid>
      )}
    </Wrapper>
  );
}

export default Reports;
