import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosFetch = (url, body) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(false);
    body !== null &&
      axios
        .post(url, body, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((response) => setData(response.data))
        .catch((error) => setError(error.message))
        .finally(() => setLoaded(true));

    body === null &&
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((response) => setData(response.data))
        .catch((error) => setError("Something went wrong!"))
        .finally(() => {
          setLoaded(true);
        });
    return;
  }, [body?.search, url]);
  return { data, error, loaded };
};

export default useAxiosFetch;
