import styled from "styled-components";

export const Wrapper = styled.button`
  padding: 14px 20px;
  background: var(--blue);
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: 0.2s all ease-in-out;
  position: relative;
  background-color: var(--main);
  color: var(--text);
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &::after {
    content: "";
    transition: opacity 0.15s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.06);
    opacity: 0;
    border-radius: 4px;
  }
  &.full {
    width: 100%;
  }
  &:hover {
    background-color: var(--main);
    &::after {
      opacity: 1;
    }
  }
`;
