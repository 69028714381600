import { useContext } from "react";
import Avatar from "react-avatar";
import { Wrapper, Admin } from "./header.e";
import { TbMenu2 } from "react-icons/tb";
import { HeaderContext } from "../../../context/header";

function Header({ title }) {
  const { setShow } = useContext(HeaderContext);
  return (
    <Wrapper>
      <TbMenu2 fontSize={24} onClick={() => setShow(true)} />
      <h2>{title}</h2>
      <Admin>
        <Avatar
          name={
            localStorage.getItem("user") &&
            `${JSON.parse(localStorage.getItem("user")).first_name} ${
              JSON.parse(localStorage.getItem("user")).last_name
            }`
          }
        />
        <div>
          <p>
            {localStorage.getItem("user") &&
              `${JSON.parse(localStorage.getItem("user")).first_name} ${
                JSON.parse(localStorage.getItem("user")).last_name
              }`}
          </p>
        </div>
      </Admin>
    </Wrapper>
  );
}

export default Header;
