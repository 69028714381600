import styled from "styled-components";

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 340px;
  padding: 30px;
  padding-bottom: 6%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  z-index: 1000;
  img {
    width: 90px;
  }
  a {
    padding: 15px 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 18px;
    color: var(--text2);
    font-weight: 500;
    transition: 0.2s all ease-in-out;
    svg {
      fill: var(--text2);
      transition: 0.2s all ease-in-out;
    }
    &.active {
      color: var(--text);
      font-weight: 500;
      background-color: #c8ee44;
      border-radius: 8px;
      svg {
        fill: var(--text);
      }
    }
  }
  .closer {
    position: absolute;
    top: 30px;
    right: 40px;
    display: none;
  }
  @media (max-width: 1000px) {
    transition: 0.25s all ease-in-out;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    .closer {
      display: block;
    }
  }
`;

export const Links = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
