import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 12px 15px;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  gap: 15px;
  width: 335px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  font-weight: 300;
  i {
    transform: translateY(1px);
    color: #6f767e;
    margin-right: 15px;
  }
  input {
    color: var(--text-body);
    padding: 0;
    padding-left: 2px;
    border: none;
    flex: 1;
    font-size: 16px;
  }
  &.small {
    width: 150px !important;
  }
`;
