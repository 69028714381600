import { useState } from "react";
import Header from "../../components/common/header/header";
import { Wrapper, NewsWrapper } from "./news.e";
import useAxiosFetch from "../../hooks/useAxios";
import NewsEdit from "./news-edit";
import { FlexRow } from "../../components/common/global/global";
import Search from "../../components/common/search/search";
import CreateNews from "./create-news";

function News() {
  const [search, setSearch] = useState("");
  const { data: news, loaded } = useAxiosFetch(
    `https://tiin-cashback.in1.uz/api/banner?status=all&page=1&limit=10&search=${search}`,
    null
  );

  return (
    <Wrapper>
      <Header title="News" />
      <FlexRow className="mt-2" style={{ alignItems: "flex-start" }}>
        <Search value={search} onChange={setSearch} />
        <CreateNews />
      </FlexRow>
      <NewsWrapper>
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Status</th>
              <th>Date</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loaded &&
              news?.data.map((item, i) => <NewsEdit key={i} news={item} />)}
          </tbody>
        </table>
      </NewsWrapper>
    </Wrapper>
  );
}

export default News;
